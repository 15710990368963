@import '@styles/global/vars';

.topbar {
    min-height:  $HEIGHT-TOPBAR;
    background-color: $WHITE;
    transition: 0.5s;
    .topbar-wrapper{
        width: 100%;
        height: $HEIGHT-TOPBAR;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;

        .toggle{
            position: relative;
            top: 0;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.8em;
            cursor: pointer;
        }

        .search{
            position: relative;
            width: 400px;
            margin: 0 10px;

            label{
                position: relative;
                width: 100%;

                input{
                    width: 100%;
                    height: 35px;
                    border-radius: 5px;
                    padding:  5px 20px;
                    padding-left: 35px;
                    font-size: 14px;
                    outline: none;
                    border: 1px solid $BLACK;
                }
                i{
                    position: absolute;
                    top: 0;
                    left: 10px;
                    font-size: 1.2em;
                    ;
                }
            }
        }

        .user{
            position: relative;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}


