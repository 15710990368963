@import "@styles/global/vars";

.sidebar {
  &.active {
    width: $WIDTH-SIDEBAR;
  }
  width: $WIDTH-SIDEBAR-MINI;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: $MAIN1;
  border-left: 5px solid $MAIN1;
  transition: 0.5s;
  overflow: hidden;
  .sidebar-wrapper {
    height: 100%;
    ul {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      li {
        position: relative;
        width: 100%;
        list-style: none;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        a {
          position: relative;
          display: block;
          width: 100%;
          display: flex;
          text-decoration: none;
          color: $WHITE;
          .item-icon {
            position: relative;
            display: block;
            min-width: 40px;
            height: 40px;
            line-height: 60px;
            text-align: center;
            i {
              font-size: 1.2em;
            }
          }
          .item-title {
            position: relative;
            display: block;
            padding: 0 10px;
            height: 60px;
            font-size: 14px;
            line-height: 60px;
            text-align: start;
            white-space: nowrap;
          }
        }
        &:hover {
          background-color: $WHITE;
          a {
            color: $MAIN1;
          }
          a::before {
            content: "";
            position: absolute;
            right: 0;
            top: -35px;
            width: 35px;
            height: 35px;
            background-color: transparent;
            border-radius: 50%;
            box-shadow: 25px 25px 0 6px $WHITE;
            pointer-events: none;
          }
          a::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: -35px;
            width: 35px;
            height: 35px;
            background-color: transparent;
            border-radius: 50%;
            box-shadow: 25px -25px 0 6px $WHITE;
            pointer-events: none;
          }
        }
        &:nth-child(1) {
          margin-bottom: 40px;
          pointer-events: none;
          .item-title {
            font-weight: bold;
          }
        }
      }
    }
  }
}
