@import "@styles/global/vars";

.layout {
  height: 100%;
  width: 100%;
  .body-area {
    position: absolute;
    left: $WIDTH-SIDEBAR-MINI;
    width: calc(100% - $WIDTH-SIDEBAR-MINI);
    height: 100%;
    transition: 0.5s;
    overflow: hidden;
    &.active {
      left: $WIDTH-SIDEBAR;
      width: calc(100% - $WIDTH-SIDEBAR);
    }
  }
}
