@import "@styles/global/vars";

@font-face {
  font-family: "Roboto";
  src: url("@fonts/roboto-v29-latin-regular.woff2") format("woff2"),
    url("@fonts/roboto-v29-latin-regular.woff") format("woff");
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  list-style: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-size: $FONT-SIZE;
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html {
  font-family: $FONT-FAMILY;
  font-size: $FONT-SIZE;
  background-color: $WHITE;
  overflow-x: hidden;
  height: 100vh;
}
input,
select,
button,
textarea,
a {
  font-family: $FONT-FAMILY;
  font-size: $FONT-SIZE;
  font-style: normal;
}
a {
  text-decoration: none;
  color: $BLACK;
}
ul,
ol,
li {
  padding: 0;
  margin: 0;
  font-size: $FONT-SIZE;
}
svg {
  vertical-align: middle;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0; /*Ocultamos la UI de IE */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/** PAGE */
::-webkit-scrollbar {
  width: 5px; /* ancho de toda la barra de desplazamiento */
  height: 5px;
}
::-webkit-scrollbar-track {
  background: $MAIN3; /* color del área de seguimiento */
}
::-webkit-scrollbar-thumb {
  background-color: $MAIN2; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 0px solid $MAIN1; /* creates padding around scroll thumb */
}
::-webkit-scrollbar-thumb:hover {
  background-color: $MAIN1;
}

