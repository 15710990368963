/** COLORS */
$BLACK: #000;
$WHITE: #fff;
$MAIN1: #2482C5;
$MAIN2: #47B9EB;
$MAIN3: #E3E3E3;


/** PAGE */
$FONT-FAMILY: 'Roboto', sans-serif;
$FONT-SIZE: 14px;

/** SIDEBAR */
$WIDTH-SIDEBAR: 180px;
$WIDTH-SIDEBAR-MINI: 50px;

/** TOPBAR */
$HEIGHT-TOPBAR: 60px;

/*|---------------| BREAKPOINTS|---------------*/

// Usage example:  @media #{$BREAKPOINT-DESK}

$BREAKPOINT-DESK: "only screen and (min-width: 1280px)";
$BREAKPOINT-TABLET-LAND: "only screen and (min-width: 975px)";
$BREAKPOINT-TABLET: "only screen and (min-width: 600px)";
$BREAKPOINT-MOBILE-LAND: "only screen and (min-width: 480px)";