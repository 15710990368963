@import "@styles/global/vars";

.login-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $MAIN1;
  .login-page-wrapper {
    padding: 0 5%;
    max-width: 550px;
    width: 100vh;
    .login-wrapper {
      background-color: rgba($color: $WHITE, $alpha: 0.5);
      border-radius: 16px;
      padding: 15px;
      text-align: center;
      .login-logo {
        border-radius: 30px;
        margin-bottom: 5px;
      }
      .login-title {
        font-weight: 700;
      }
      .login-form {
        margin: 25px 0;
        .input {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin: 10px 0;
          label {
            text-align: left;
            font-size: 14px;
            margin-bottom: 5px;
          }
          input {
            height: 40px;
            border-radius: 5px;
            border: 1px solid $MAIN3;
            padding: 15px;
          }
        }
        .button {
          button {
            background-color: $MAIN1;
            border: none;
            width: 100%;
            padding: 10px 0;
            border-radius: 5px;
            cursor: pointer;
            color: $WHITE;
            &:hover {
              opacity: 0.8;
            }
            i {
              margin-left: 5px;
            }
          }
        }
      }
      .error-msg{
        font-size: 10px;
        color: #c80000;
      }
    }
  }
}
