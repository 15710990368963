@import "@styles/global/vars";
.pagination-table {
  flex-wrap: wrap;
  .page-item {
    &.active {
      .page-link {
        background-color: $MAIN2;
        border-color: $MAIN2;
        color: $WHITE;
      }
    }
    .page-link{
        color: $MAIN2;
    }
  }
}
