@import "@styles/global/vars";

.certificate-page {
  height: 100%;
  .page-title {
    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
    }
  }
  .tabs-wrapper {
    padding-bottom: 20px;
    .nav-tabs {
      font-size: 13px;
      font-weight: 700;
      .nav-link {
        color: $MAIN1;
        &.active {
          color: #495057;
        }
      }
    }
  }
  .tab-content {
    border: 1px solid #dee2e6;
    border-top: none;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    font-size: 13px;
    padding: 20px 0;
  }
  .search-wrapper{
    padding: 0 10px;
    .accordion-button{
      padding: 10px 15px;
      flex-wrap: wrap;
      word-break: break-word;
      i {
        margin-right: 5px;
      }
    }
  }
  .btn-add-wrapper {
    padding: 0 10px;
    width: 100%;
    .default-btn {
      margin: 15px 0;
      flex-wrap: wrap;
      word-break: break-word;
      &:hover{
        opacity: 0.7;
      }
      i {
        margin-right: 5px;
      }
    }
    .import-btn{
      margin: 0 -10px;
      margin-top: 10px;
      background-color: #00bb2c;
      border-color: #00bb2c;  
      width: calc(100% + 20px);
    }
    .add-btn {
      background-color: $MAIN2;
      border-color: $MAIN2;
    }
  }
  .import-export-btn-wrapper{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  
  .tab-table {
    overflow-y: auto;
    overflow-x: hidden;
    height: 450px;
    padding: 0 10px;
    .col-btns {
      vertical-align: middle;
      .btn-wrapper {
        display: flex;
        @media (max-width: 576px) {
          display: block;
        }
        .edit-btn {
          background-color: #2482c5;
          border-color: #2482c5;
        }
        .delete-btn {
          background-color: #e30000;
          border-color: #e30000;
          margin-left: 5px;
          @media (max-width: 576px) {
            margin: 0;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .tab-import-and-export{
    padding: 0 10px;
  }
  .pagination-wrapper {
    margin: 20px 0;
    padding-left: 10px;
  }
}
